import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Row, Col } from "react-bootstrap"

const Header = ({ siteTitle }) => (
	<StaticQuery
		query={graphql`
		query {
			headerLogo: file(relativePath: { eq: "full-moon-logo.png" }) {
			  childImageSharp {
				# Specify the image processing specifications right in the query.
				# Makes it trivial to update as your page's design changes.
				fluid(maxWidth: 500) {
				  ...GatsbyImageSharpFluid
				}
			  }
			}
		}
		`}
		render={data => (
		<>
		<header>
			<Row className="p-2">
				<Col lg="4" md="6" sm="8" xs="10" id="header-logo-box">
					<Link to="/" className="link-no-style">
						<Img fluid={data.headerLogo.childImageSharp.fluid} />
					</Link>
				</Col>
				<Col lg="8" md="6" sm="12"  id="header-info-box">
				<span id="header-phone" className="text-color-primary h3">(919) 623-0361</span>
				<span>154 Mine Lake Ct., Raleigh, NC 27615</span>
				</Col>
			</Row>
		</header>
		</>
		)}
	/>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
